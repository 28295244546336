import React from "react";
import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";
import "@fortawesome/fontawesome/styles.css";

const Main = styled.main`
  position: relative;
  z-index: 2;

  background-size: contain;
  background-blend-mode: overlay;
  background-color: #ffffff;

  ::before,
  ::after {
    background-blend-mode: overlay;
    background-color: #ffffff;
  }

  @media only screen and (min-width: 770px) {
    background-blend-mode: overlay;
    background-color: #ffffff;

    ::before,
    ::after {
      background-blend-mode: overlay;
      background-color: #ffffff;
    }
  }
`;

const Layout = ({ children, pageTitle }) => (
  <div>
    <Header pageTitle={pageTitle} />
    <Main>{children}</Main>
    <Footer />
  </div>
);

export default Layout;
