import React from "react";
import SEO from "../seo";

import Nav from "./nav";
// const seoImage = "@images/logos/3kids-700x547.png";

class Header extends React.Component {
  render() {
          return (
            <>
              <SEO pageTitle={this.props.pageTitle} />
              <Nav />
            </>
          );
  }
}

export default Header;
