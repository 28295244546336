import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import logoImg from "@images/logos/3kids-100x78.png";
import "@styles/styles.scss";

const StyledLink = styled(Link)`
  outline: none;
`;

export default () => {
  const [isActive, setisActive] = React.useState(false);
  return (
    <nav className="navbar is-fixed-top" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item">
          <img src={logoImg} alt="Nannies" />
        </a>
        <div className="navbar-item">
          <StyledLink to="/">
            <h1 className="subtitle is-3 nav-logo">My Boulder Nanny</h1>
          </StyledLink>
        </div>

        <a
          onClick={() => {
            setisActive(!isActive);
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          htmlFor="nav-toggle-state"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">For Families</a>
            <div className="navbar-dropdown">
              <StyledLink className="navbar-item" to="/families/how">
                How It Works
              </StyledLink>
              <StyledLink className="navbar-item" to="/families/pricing">
                Pricing
              </StyledLink>
              <StyledLink className="navbar-item" to="/applications/family">
                Apply
              </StyledLink>
              <StyledLink className="navbar-item" to="/families/nannyboard">
                Nannies
              </StyledLink>
              <StyledLink className="navbar-item" to="/families/occasionalcare">
                Babysitting
              </StyledLink>
            </div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">For Nannies</a>
            <div className="navbar-dropdown">
              <StyledLink className="navbar-item" to="/applications/nanny">
                Apply
              </StyledLink>
              <StyledLink className="navbar-item" to="/nannies/jobs">
                Nanny Jobs
              </StyledLink>
              <StyledLink className="navbar-item" to="/nannies/babysitting">
                Babysitting
              </StyledLink>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
