import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const query = graphql`
  {
    site {
      siteMetadata {
        title
        longTitle
        author
      }
    }
  }
`;

const FooterWrap = styled.footer`
  bottom: 0;
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  background-color: #ffffff;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 85%;

  @media only screen and (min-width: 770px) {
    width: 65%;
  }
`;

const FooterSection = styled.div`
  width: 50%;
  z-index: 4;
`;

const FooterHeader = styled.h5`
  margin-bottom: 0.3rem;
  font-size: 1em;
`;

const StyledLink = styled.a`
  display: block;
  &:hover {
    text-decoration: none;
    color: F0F0F0;
  }
`; // todo use a theme color, yellow?

const LinkIcon = styled.i`
  margin: 0 5% 0 0;
  font-size: 1.3rem;
`;

const Footer = () => (
  <StaticQuery
    query={query}
    render={(data) => {
      const { siteMetadata } = data.site;
      return (
        <FooterWrap>
          <FooterContent>
            <FooterSection>
              <FooterHeader />
              <span style={{ fontSize: "0.85rem" }}>
                © {new Date().getFullYear()}, My Boulder Babysitter, LLC
              </span>
            </FooterSection>
            <FooterSection>
              <FooterHeader />
              <div style={{ fontSize: "0.85rem" }}>
                <p>My Boulder Nanny, LLC</p>
                <p></p>
                <p>Boulder, CO 80308</p>
              </div>
            </FooterSection>
            <FooterSection>
              <StyledLink href="mailto: myboulderbabysitter@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} />
                <span> Email us.</span>
              </StyledLink>
              <StyledLink href="https://www.instagram.com/myboulderbabysitter">
                <FontAwesomeIcon icon={faInstagram} />
                <span> Follow us.</span>
              </StyledLink>
            </FooterSection>
          </FooterContent>
        </FooterWrap>
      );
    }}
  />
);

export default Footer;
